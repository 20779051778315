import React from "react";

import "./profileevent.css";
/* test */
const ProfileEvent = (props) => {
  let calDate = new Date(props.event.date);
  let day = calDate.toLocaleString('en-us', { weekday: 'long' });
  const timeString = props.event.time
// Prepend any date. Use your birthday.
  const timeString12hr = new Date('1970-01-01T' + timeString + 'Z')
  .toLocaleTimeString('en-US',
    {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
  );
  return (
    <>
    <div className="col-lg-3">
      <div className="containerProfileEvent">
        <div className="containerTopProfileEvent">
          <h1>{props.event.name}</h1>
        </div>

        <ul className="container__subheading">
          <li>{day}</li>
          <li>{calDate.getUTCDate() + '/' + (calDate.getMonth()+1)}</li>
          <li>{timeString12hr}</li>
        </ul>

        <p className="container__p">{props.event.content}</p>
      </div>
    </div>
    </>
    
  );
};

export default ProfileEvent;
